@import '~katex/dist/katex.min.css';
@import '~ag-grid-community/src/styles/ag-grid.scss';
@import '~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin';
@import '~ag-grid-community/src/styles/ag-theme-balham-dark/sass/ag-theme-balham-dark-mixin';
@import '@jgp-er-dev/pdf/dist/index.css';
@import '@jgp-er-dev/components/dist/index.css';

.ag-theme-alpine {
  @include ag-theme-alpine(
    (
      font-family: 'Work Sans',
    )
  );
}

.ag-theme-alpine-compact {
  @include ag-theme-alpine(
    (
      font-family: 'Work Sans',
      grid-size: 4px,
      icon-size: 10px,
      font-size: 14px,
      row-height: 30px,
      cell-horizontal-padding: 2.5px,
    )
  );
  .ag-header-group-cell-label {
    justify-content: center;
  }
}

.ag-theme-alpine-compact-mobile {
  @include ag-theme-alpine(
    (
      font-family: 'Work Sans',
      grid-size: 3px,
      icon-size: 10px,
      font-size: 10px,
      widget-horizontal-spacing: 2px,
      cell-horizontal-padding: 4px,
      cell-widget-spacing: 0px,
    )
  );
}

.ag-theme-balham-dark {
  @include ag-theme-balham-dark(
    (
      font-family: 'Work Sans',
    )
  );
}

.ag-theme-balham-dark-launchpad {
  @include ag-theme-balham-dark(
    (
      border-color: ag-derived(background-color, $mix: foreground-color 0%),
      font-family: 'Work Sans',
      font-size: 12px,
      grid-size: 2.5px,
      cell-horizontal-padding: 5px,
    )
  );
  span.ag-header-cell-text {
    color: #ffffff;
    font-weight: 600;
  }
  span.ag-group-value {
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
  }
  span.ag-group-child-count {
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
  }
}

.ag-theme-balham-dark-launchpad-mobile {
  @include ag-theme-balham-dark(
    (
      font-family: 'Work Sans',
      grid-size: 2.5px,
      cell-horizontal-padding: 0px,
    )
  );
  span.ag-header-cell-text {
    color: #ffffff;
    font-weight: 600;
  }
}

.ag-theme-alpine-launchpad {
  @include ag-theme-alpine(
    (
      border-color: ag-derived(background-color, $mix: foreground-color 0%),
      font-family: 'Work Sans',
      font-size: 12px,
      grid-size: 2.5px,
      cell-horizontal-padding: 5px,
    )
  );
  span.ag-header-cell-text {
    color: #000000;
    font-weight: 600;
  }
  span.ag-group-value {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
  }
  span.ag-group-child-count {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
  }
}

.ag-theme-alpine-launchpad-mobile {
  @include ag-theme-alpine(
    (
      font-family: 'Work Sans',
      font-size: 10px,
      grid-size: 2.5px,
      cell-horizontal-padding: 0px,
    )
  );
  span.ag-header-cell-text {
    color: #000000;
    font-weight: 600;
  }
}

.ag-theme-cool-table {
  @include ag-theme-alpine(
    (
      font-family: 'Work Sans',
      row-height: 35px,
      font-size: 12px,
      grid-size: 2.5px,
      cell-horizontal-padding: 3px,
    )
  );
  .ag-header-group-cell-label {
    justify-content: center;
  }
}

.ag-theme-cool-table-mobile {
  @include ag-theme-alpine(
    (
      font-family: 'Work Sans',
      row-height: 35px,
      font-size: 10px,
      cell-horizontal-padding: 3px,
    )
  );
  .ag-header-group-cell-label {
    justify-content: center;
  }
}
