.toast {
  animation: appear 200ms;
}

@keyframes appear {
  from {
    transform: translate(100%);
  }
  to {
    transform: translate(0);
  }
}
